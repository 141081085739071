import APIAbstract from './APIAbstract'
import { Root } from '../configs/serverConfig'

class AuthApi extends APIAbstract {
    userLogin(data = {}) {
        return this.get('/admin/auth/userLogin', data)
    }
    sessionCheck(data = {}) {
        return this.get('/admin/auth/sessionCheck', data)
    }
    sendLogout(data = {}) {
        return this.delete('/admin/auth/sendLogout', data)
    }
    forgotPasswordRequest(data = {}) {
        return this.post('/admin/auth/forgotPasswordRequest', data)
    }
    changePassword(data) {
        return this.post('/admin/auth/changePassword', data)
    }
}
export default new AuthApi(`${Root.server_url}`)
