import { Slide } from 'react-toastify'

const toastConfig = {
    icon: false,
    transition: Slide,
    // hideProgressBar: true,
    autoClose: 5000
}

export default toastConfig
