export const Root =
    process.env.NODE_ENV === 'development'
        ? {
              server_url: 'https://admin.ourdemosite.info',
              //   server_url: 'http://localhost:2024',
              site_url: 'https://ourdemosite.info',
              token: 'admin.ourdemosite.info',
              show: null,
              hide: null
          }
        : {
              server_url: 'https://admin.ourdemosite.info',
              site_url: 'http://ourdemosite.info',
              token: 'admin.ourdemosite.info',
              show: null,
              hide: null
          }
