import React, { useContext } from "react"
import { LoadingContext } from '@src/utility/context/loadingContext'
import { Spinner } from "reactstrap"

const Loading = () => {

    const loading = useContext(LoadingContext)

    return (
        <React.Fragment>
            {loading.loadingCount ? <div className='my-loader'>
                <div className='vh-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                    <Spinner className="m-1" color="dark" />
                    <div className="loader-text">Loading</div>
                </div>
            </div> : null}
        </React.Fragment>
    )
}

export default Loading