import React, { Fragment } from "react"
import Avatar from '@components/avatar'
import { Check, Coffee, X } from "react-feather"
import { useTranslation } from 'react-i18next'

export const SuccessToast = () => {
    const { t } = useTranslation()
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='success' icon={<Check size={12} />} />
                    <h6 className='toast-title'>{t("Success")}</h6>
                </div>
            </div>
        </Fragment>
    )
}

export const LoginToastContent = ({ name }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Coffee size={12} />} />
                <h6 className='toast-title fw-bold'>Welcome, {name}</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span>You have successfully logged in as an admin user to admin panel. Now you can start to explore. Enjoy!</span>
        </div>
    </Fragment>
)

export const ApiErrorToast = ({ title }) => {
    const { t } = useTranslation()
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>{t(title)}</h6>
                </div>
            </div>
        </Fragment>
    )
}
