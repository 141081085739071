import APIAbstract from './APIAbstract'
import { Root } from '../configs/serverConfig'

class ProfileApi extends APIAbstract {
    // Profile Base Data
    getAllCountryList(data) {
        return this.post('/admin/profile/getAllCountryList', data)
    }
    getProfileAgentList(data) {
        return this.post('/admin/profile/getProfileAgentList', data)
    }

    //  ------------------------------------------------------------------

    // Profile Data
    getProfileData(data = {}) {
        return this.get('/admin/profile/getProfileData', data)
    }
    setProfileData(data = {}) {
        return this.post('/admin/profile/setProfileData', data)
    }

    //  ------------------------------------------------------------------

    // Security
    setPassword(data = {}) {
        return this.put('/admin/profile/setPassword', data)
    }
    getAllLoginHistory(data = {}) {
        return this.post('/admin/profile/getAllLoginHistory', data)
    }
    downloadCSVRecentDevices(data = {}) {
        return this.post('/admin/profile/downloadCSVRecentDevices', data)
    }

    //  ------------------------------------------------------------------

    // Permission
    getPermissionData(data = {}) {
        return this.get('/admin/profile/getPermissionData', data)
    }
    updatePermission(data = {}) {
        return this.post('/admin/profile/updatePermission', data)
    }
    updateProviderPermission(data = {}) {
        return this.post('/admin/profile/updateProviderPermission', data)
    }

    //  ------------------------------------------------------------------

    // Payment
    getPaymentGateways(data = {}) {
        return this.get('/admin/profile/getPaymentGateways', data)
    }
    getPaymentPermissionData(data = {}) {
        return this.get('/admin/profile/getPaymentPermissionData', data)
    }
    updateGatewayPermission(data = {}) {
        return this.post('/admin/profile/updateGatewayPermission', data)
    }

    //  ------------------------------------------------------------------

    // Notification
    updateNotification(data = {}) {
        return this.post('/admin/profile/updateNotification', data)
    }
    sendVerifyEmailRequest(data = {}) {
        return this.post('/admin/profile/sendVerifyEmailRequest', data)
    }
    sendVerifySMSRequest(data = {}) {
        return this.post('/admin/profile/sendVerifySMSRequest', data)
    }
    sendVerifyCode(data = {}) {
        return this.post('/admin/profile/sendVerifyCode', data)
    }

    getPlayerPromoCode(data) {
        return this.get('/admin/profile/getPlayerPromoCode', data)
    }
    setPlayerPromoCode(data) {
        return this.post('/admin/profile/setPlayerPromoCode', data)
    }
    sendPromoCodeToUser(data) {
        return this.post('/admin/profile/sendPromoCodeToUser', data)
    }

    getAllAdminSidebar(data) {
        return this.post('/admin/profile/getAllAdminSidebar', data)
    }
}
export default new ProfileApi(`${Root.server_url}`)
