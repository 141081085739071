import create from 'zustand'
import AuthApi from '../api/AuthApi'
import ProfileApi from '../api/ProfileApi'
import { toast } from 'react-toastify'
import { LoginToastContent } from '@src/views/ui-elements/ToastContents'
import ToastConfig from '@src/configs/toastConfig'
import { Root } from '../configs/serverConfig'
import LogoImage from '@src/assets/images/logo/logo.png'
import { t } from 'i18next'

const useStore = create((set) => ({
    Auth: {
        isAuth: false,
        isChecked: false,
        user: {}
    },
    sidebar: [],
    currencies: [],
    logoImage: LogoImage,
    userLogin: async (data) => {
        Root.show()
        const res = await AuthApi.userLogin(data)
        Root.hide()
        if (res) {
            const { token, user } = res.data
            AuthApi.setToken(token)
            toast.success(
                <LoginToastContent name={user.username} />,
                ToastConfig
            )
            setTimeout(() => {
                window.location.assign('/dashboard')
            }, 500)
        }
    },
    sessionCheck: async () => {
        if (AuthApi.getToken()) {
            Root.show()
            const res = await AuthApi.sessionCheck()
            Root.hide()
            if (res) {
                set({
                    Auth: {
                        isAuth: true,
                        user: res.data,
                        isChecked: true
                    }
                })
            } else {
                AuthApi.clearToken()
                setTimeout(() => {
                    window.location.reload()
                }, 100)
            }
        } else {
            set({ Auth: { isAuth: false, user: {}, isChecked: true } })
        }
    },
    sendLogout: async () => {
        Root.show()
        await AuthApi.sendLogout()
        Root.hide()
        AuthApi.clearToken()
        window.location.assign('/login')
    },
    forgotPasswordRequest: async (data) => {
        Root.show()
        const flag = await AuthApi.forgotPasswordRequest(data)
        Root.hide()
        if (flag) {
            toast.success('reset password sent')
        }
    },
    changePassword: async (data) => {
        Root.show()
        const res = await AuthApi.changePassword(data)
        Root.hide()
        if (res) {
            toast.success(t('Changed Scuccessfully.'), ToastConfig)
            return true
        } else {
            return false
        }
    }
}))

export default useStore
